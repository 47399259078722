import React, { useState, createContext } from "react";

// create context
export const SidebarContext = createContext();

export const SidebarProvider = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isSidebarActive, setIsSidebarActive] = useState(false);
  const [isMenuShort, setIsMenuShort] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDrawerOpenStudent, setIsDrawerOpenStudent] = useState(false);
  const [isDrawerOpenGroups, setIsDrawerOpenGroups] = useState(false);
  const [isDrawerOpenEditGroups, setIsDrawerOpenEditGroups] = useState(false);
  const [isDrawerOpenManager, setIsDrawerOpenManager] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);

  const closeSidebar = () => setIsSidebarOpen(false);
  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  const closeSidebarActive = () => setIsSidebarActive(false);
  const toggleSidebarActive = () => setIsSidebarActive(!isSidebarActive);

  const closeMenu = () => setIsMenuShort(false);
  const toggleMenu = () => setIsMenuShort(!isMenuShort);

  const closeDrawer = () => setIsDrawerOpen(false);
  const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);

  const closeDrawerStudent = () => setIsDrawerOpenStudent(false);
  const toggleDrawerStudent = () => setIsDrawerOpenStudent(!isDrawerOpenStudent);

  const closeDrawerGroups = () => setIsDrawerOpenGroups(false);
  const toggleDrawerGroups = () => setIsDrawerOpenGroups(!isDrawerOpenGroups);

  const closeDrawerEditGroups = () => setIsDrawerOpenEditGroups(false);
  const toggleDrawerEditGroups = () => setIsDrawerOpenEditGroups(!isDrawerOpenEditGroups);

  const closeDrawerManager = () => setIsDrawerOpenManager(false);
  const toggleDrawerManager = () =>
    setIsDrawerOpenManager(!isDrawerOpenStudent);

  const closeModal = () => setIsModalOpen(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  // const value = useMemo(
  //   () => ({
  //     isSidebarOpen,
  //     toggleSidebar,
  //     closeSidebar,
  //     isDrawerOpen,
  //     toggleDrawer,
  //     closeDrawer,
  //     setIsDrawerOpen,
  //     isModalOpen,
  //     toggleModal,
  //     closeModal,
  //     isUpdate,
  //     setIsUpdate,
  //   }),

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [isSidebarOpen, isDrawerOpen, isModalOpen, isUpdate]
  // );

  return (
    <SidebarContext.Provider
      value={{
        isSidebarOpen,
        toggleSidebar,
        closeSidebar,
        isDrawerOpen,
        toggleDrawer,
        closeDrawer,
        setIsDrawerOpen,
        isModalOpen,
        toggleModal,
        closeModal,
        isUpdate,
        setIsUpdate,
        isMenuShort,
        closeMenu,
        toggleMenu,
        isDrawerOpenStudent,
        toggleDrawerStudent,
        closeDrawerStudent,
        isDrawerOpenGroups,
        isDrawerOpenEditGroups,
        toggleDrawerGroups,
        closeDrawerGroups,
        isDrawerOpenManager,
        toggleDrawerManager,
        closeDrawerManager,
        closeDrawerEditGroups,
        toggleDrawerEditGroups,
        isSidebarActive,
        closeSidebarActive,
        toggleSidebarActive
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};
